/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { NotePencil, PaperPlaneTilt, Trash } from '@phosphor-icons/react';
import { MinusCircle } from '@phosphor-icons/react/dist/ssr';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { groupBy } from 'lodash';
import {
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_SortingState,
  MaterialReactTable,
  MRT_Column,
  MRT_PaginationState,
  MRT_Row,
  MRT_RowSelectionState,
  MRT_TableInstance,
  MRT_Updater,
  useMaterialReactTable,
} from 'material-react-table';
import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';

import DeleteModal from 'components/Core/DeleteModal';
import {
  AttendanceTypeColumnTitle,
  AttendanceTypes,
  AttendedColumnTitle,
  AttendedStatuses,
  GuestBulkUpdateOptionGroups,
  RsvpColumnTitle,
  RsvpStatuses,
} from 'components/Events/Controls/GuestList/EventGuests.constants';
import EventGuestsEmptyState from 'components/Events/Controls/GuestList/EventGuestsEmptyState';
import EventGuestsSelect from 'components/Events/Controls/GuestList/EventGuestsSelect';
import EventGuestsTableNameCell from 'components/Events/Controls/GuestList/EventGuestsTableNameCell';
import EventGuestStatusChip from 'components/Events/Controls/GuestList/EventGuestStatusChip';
import UpdateAttendanceButton from 'components/Events/Controls/GuestList/UpdateAttendanceButton';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { ActionButtonMenuItem } from 'components/shared/dataGrid/FtnDataGridToolbar';
import Filters from 'components/shared/Filters';
import MRTTopToolbarWrapper from 'components/shared/MRTTopToolbarWrapper';
import {
  getInitialPagination,
  getInitialSorting,
  getServerSideMRTOptions,
} from 'constants/table.constants';
import { Invitee } from 'gql/graphql';
import { useEventGuests } from 'hooks/useEventGuests';
import { useMuiPaletteColor } from 'hooks/useMuiPaletteColor';
import useSelectCommunicationToSend from 'hooks/useSelectCommunicationToSend';

const EventGuestsTable = ({
  eventId,
  eventName,
}: {
  eventId: number;
  eventName: string;
}) => {
  const initialPagination = getInitialPagination({ pageSize: 20 });
  const initialSorting = getInitialSorting('fullName');

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [deletingRow, setDeletingRow] = useState<MRT_Row<Invitee> | undefined>(
    undefined
  );
  const [deletingRows, setDeletingRows] = useState<number[] | undefined>(
    undefined
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [isShowingScheduledGuests, setIsShowingScheduledGuests] =
    useState(true);
  const [pagination, setPagination] =
    useState<MRT_PaginationState>(initialPagination);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [sorting, setSorting] = useState<MRT_SortingState>(initialSorting);

  const computedColumnFilters = useMemo(() => {
    if (!isShowingScheduledGuests) {
      return [
        ...columnFilters,
        {
          id: 'inviteScheduled',
          value: ['false'],
        },
      ];
    }
    return columnFilters;
  }, [columnFilters, isShowingScheduledGuests]);

  const {
    actions: { updateGuestList },
  } = useContext(ManagerContext);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const { getValidColorName } = useMuiPaletteColor();

  const { open } = useSelectCommunicationToSend();

  const {
    data,
    isPending,
    isRefetching,
  }: { data: any; isPending: boolean; isRefetching: boolean } = useEventGuests({
    columnFilters: computedColumnFilters,
    eventId,
    globalFilter,
    pagination,
    sorting,
  });

  const showGuestUpdatedSnackbar = useCallback(
    (guest: Invitee) => {
      enqueueSnackbar(`${guest?.fullName} updated!`, {
        variant: 'success',
      });
    },
    [enqueueSnackbar]
  );

  const handleGlobalFilterChange = (updater: MRT_Updater<any>) => {
    setGlobalFilter((prevGlobalFilter) =>
      updater instanceof Function ? updater(prevGlobalFilter) : updater
    );
    if (pagination.pageIndex !== 0) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
  };

  const columns = useMemo<MRT_ColumnDef<Invitee>[]>(
    () => [
      {
        accessorKey: 'fullName',
        Cell: EventGuestsTableNameCell,
        enableEditing: false,
        grow: true,
        header: 'Guest',
        size: 200,
      },
      {
        accessorKey: 'status',
        Cell: ({ row }: { row: MRT_Row<Invitee> }) => (
          <EventGuestStatusChip row={row} />
        ),
        enableEditing: false,
        header: RsvpColumnTitle,
        size: 180,
      },
      {
        accessorKey: 'attended',
        Cell: (params) =>
          !params.row.original.inviteScheduled && (
            <EventGuestsSelect
              handleChange={(newValue) => {
                updateGuestList(
                  [{ attended: newValue, id: params.row.original.id }],
                  'update',
                  () => {
                    showGuestUpdatedSnackbar(params.row.original);
                    queryClient.invalidateQueries({
                      queryKey: ['event', eventId],
                    });
                  },
                  'attended'
                );
              }}
              id={params.row.original.id}
              label={AttendedColumnTitle}
              options={AttendedStatuses}
              value={params.row.original?.attended || ''}
            />
          ),
        enableColumnFilter: false,
        header: AttendedColumnTitle,
      },
      {
        accessorKey: 'attendanceType',
        Cell: ({ row }: { row: MRT_Row<Invitee> }) =>
          typeof row.original.attended === 'string' &&
          row.original.attended === 'yes' ? (
            <EventGuestsSelect
              handleChange={(newValue) => {
                updateGuestList(
                  [{ attendance_type: newValue, id: row.original.id }],
                  'update',
                  () => {
                    showGuestUpdatedSnackbar(row.original);
                    queryClient.invalidateQueries({
                      queryKey: ['event', eventId],
                    });
                  },
                  'attendance_type'
                );
              }}
              id={row.original.id}
              label={AttendanceTypeColumnTitle}
              options={AttendanceTypes}
              value={row.original?.attendanceType || ''}
            />
          ) : null,
        enableColumnFilter: false,
        header: AttendanceTypeColumnTitle,
      },
      {
        accessorFn: (row: Invitee) =>
          dayjs(row?.updatedAt)?.format('MM/DD/YYYY'),
        accessorKey: 'updated_at',
        enableEditing: false,
        header: 'Last updated',
        size: 160,
      },
    ],
    [eventId, queryClient, showGuestUpdatedSnackbar, updateGuestList]
  );

  const selectedRowCount = useCallback(
    () => (rowSelection ? Object.keys(rowSelection).length : 0),
    [rowSelection]
  );

  const hasSelectedRows = useCallback(
    () => rowSelection && Object.values(rowSelection).length > 0,
    [rowSelection]
  );

  const deleteModalProps = useMemo(
    () => ({
      itemCount: deletingRow ? 1 : deletingRows?.length,
      noun: 'guest',
      owner: `the guest list for "${eventName}"`,
      preposition: 'on',
      verb: 'Remove',
    }),
    [deletingRow, deletingRows?.length, eventName]
  );

  const removeGuests = (
    done: () => unknown,
    table: MRT_TableInstance<Invitee>
  ) => {
    updateGuestList(
      deletingRow
        ? [{ id: deletingRow.original?.id }]
        : deletingRows?.map((id) => ({ id })),
      'remove',
      () => {
        // callback && callback();
        table?.resetRowSelection();
        done();
        setDeletingRow(undefined);
        setDeletingRows(undefined);
        queryClient.invalidateQueries({
          queryKey: ['event', eventId],
        });
      }
    );
  };

  const emailGuests = () => {
    open({ method: 'email', type: 'message' });
  };

  const defaultOptions = getServerSideMRTOptions<Invitee>(
    `calc(100vh - 300px)`,
    true
  );

  const table = useMaterialReactTable({
    ...defaultOptions,
    columnFilterDisplayMode: 'custom',
    columns,
    data: data?.invitees?.invitees || [],
    enableRowActions: true,
    enableRowSelection: true,
    enableStickyFooter: true,
    enableStickyHeader: true,
    muiTableBodyCellProps: ({
      column,
      row,
    }: {
      column: MRT_Column<Invitee, unknown>;
      row: MRT_Row<Invitee>;
    }) => ({
      ...defaultOptions.muiTableBodyCellProps,
      ...(['fullName', 'status', 'updated_at'].includes(column.id) && {
        onClick: () => {
          row.toggleSelected();
        },
        style: {
          cursor: 'pointer',
        },
      }),
    }),
    onGlobalFilterChange: handleGlobalFilterChange,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    positionActionsColumn: 'last',
    renderEmptyRowsFallback: () => (
      <Box
        alignItems='center'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        pt={4}
        width='100%'
      >
        {isPending || isRefetching ? (
          <CircularProgress variant='indeterminate' />
        ) : (
          <EventGuestsEmptyState />
        )}
      </Box>
    ),
    renderRowActions: ({ row, table }) =>
      row.original.inviteScheduled
        ? []
        : [
            <IconButton
              disabled={
                (Object.keys(table.getState().rowSelection)?.length === 1 &&
                  !table.getState().rowSelection[String(row.id)]) ||
                Object.keys(table.getState().rowSelection)?.length > 1
              }
              key='delete'
              onClick={() => setDeletingRow(row)}
            >
              <MinusCircle />
            </IconButton>,
          ],
    renderToolbarAlertBannerContent: ({ table }) => (
      <Box alignItems='center' display='flex' flexDirection='row' p={1}>
        {hasSelectedRows() ? (
          <>
            <Typography>{`${selectedRowCount()} selected`}</Typography>
            <Button onClick={() => table?.resetRowSelection()}>Clear</Button>
          </>
        ) : null}
      </Box>
    ),
    renderTopToolbar: ({ table }) => (
      <MRTTopToolbarWrapper
        nounPlural='guests'
        table={table}
        wrapperSxProps={{
          alignItems: 'center',
          bgcolor: 'transparent',
          display: 'flex',
          gap: 2,
        }}
      >
        <Stack direction='row' gap={1} width='100%'>
          <Stack direction='row' flexGrow={2} gap={1}>
            <Filters
              areCombined
              filters={[
                {
                  handleChange: (newValue) => {
                    const typeMap: Record<
                      'Attendance type' | 'Attended' | 'Invitation status',
                      string
                    > = {
                      'Attendance type': 'attendanceType',
                      Attended: 'attended',
                      'Invitation status': 'status',
                    };
                    const groupedFilters = groupBy(
                      newValue,
                      (filter) =>
                        typeMap[
                          filter.type as
                            | 'Attendance type'
                            | 'Attended'
                            | 'Invitation status'
                        ]
                    );
                    setColumnFilters(
                      Object.keys(groupedFilters)?.map((k) => ({
                        id: k,
                        value: groupedFilters[k]?.map((v) => v.id) || [],
                      }))
                    );
                  },
                  label: 'Filter',
                  manuallySorted: true,
                  options: [
                    ...RsvpStatuses.map((s) => ({
                      ...s,
                      type: RsvpColumnTitle,
                    })),
                    ...AttendedStatuses.map((s) => ({
                      ...s,
                      type: AttendedColumnTitle,
                    })),
                    ...AttendanceTypes.map((s) => ({
                      ...s,
                      type: AttendanceTypeColumnTitle,
                    })),
                  ]?.map((s) => ({
                    ...s,
                    color: getValidColorName({
                      colorName: s.colorName,
                      fallbackColorName: 'neutral',
                    }),
                    id: s.id === '' ? null : s.id,
                  })),
                  type: 'multi',
                },
              ]}
              flexGrow={0}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isShowingScheduledGuests}
                  inputProps={{
                    'aria-label': 'toggle scheduled guest visibility',
                  }}
                  onChange={(e) =>
                    setIsShowingScheduledGuests(e.target.checked)
                  }
                />
              }
              data-testid='show-scheduled-guests'
              label='Show scheduled guests'
              sx={{ ml: 1 }}
            />
          </Stack>
          <Stack direction='row' gap={1} justifyContent='flex-end'>
            <Collapse
              in={
                hasSelectedRows() &&
                !(table?.getState().editingRow || deletingRow)
              }
              orientation='horizontal'
            >
              <Stack direction='row' gap={1}>
                <UpdateAttendanceButton
                  icon={<NotePencil />}
                  menuItems={GuestBulkUpdateOptionGroups}
                  onClick={(field: string, option: ActionButtonMenuItem) => {
                    const selections = table?.getState().rowSelection || [];
                    const updates = Object.keys(selections).map(
                      (k: string) => ({
                        [field]: option?.id,
                        id: k,
                      })
                    );
                    updateGuestList(
                      updates,
                      'update',
                      () => {
                        enqueueSnackbar(
                          updates?.length === 1
                            ? `${
                                data?.invitees?.invitees?.find(
                                  (invitee: Invitee) =>
                                    invitee?.id === updates[0].id
                                )?.fullName || 'selected guest'
                              } updated`
                            : `${pluralize(
                                'selected guest',
                                updates.length,
                                true
                              )} updated`,
                          { variant: 'success' }
                        );
                        queryClient.invalidateQueries({
                          queryKey: ['event', eventId],
                        });
                      },
                      field
                    );
                  }}
                  title='Update attendance'
                />
                <Button
                  color='error'
                  onClick={() =>
                    setDeletingRows(
                      Object.keys(rowSelection).map((k) => Number(k))
                    )
                  }
                  startIcon={<Trash />}
                  sx={{ whiteSpace: 'nowrap' }}
                  variant='contained'
                >
                  {`Remove ${pluralize(
                    'guest',
                    Object.keys(rowSelection).length
                  )}`}
                </Button>
              </Stack>
            </Collapse>
            {Object.keys(rowSelection).length === 0 && (
              <Button
                onClick={() => emailGuests()}
                startIcon={<PaperPlaneTilt />}
                sx={{ whiteSpace: 'nowrap' }}
                variant='contained'
              >
                Email guest list
              </Button>
            )}
          </Stack>
        </Stack>
      </MRTTopToolbarWrapper>
    ),
    rowCount: data?.invitees?.totalCount ?? 0,
    state: {
      columnFilters,
      columnPinning: {
        left: ['mrt-row-select', 'fullName'],
      },
      globalFilter,
      isLoading: isPending,
      pagination,
      rowSelection,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <>
      <MaterialReactTable table={table} />
      <DeleteModal
        {...deleteModalProps}
        isPerson
        onCancel={() => {
          if (deletingRow) {
            table?.resetRowSelection();
          }
          setDeletingRow(undefined);
          setDeletingRows(undefined);
        }}
        onDelete={(done: () => unknown) => {
          removeGuests(done, table);
        }}
        show={!!deletingRow || (deletingRows && deletingRows?.length > 0)}
        subject={
          deletingRow
            ? deletingRow.getValue('fullName')
            : deletingRows && deletingRows?.length > 0
            ? 'these selections'
            : ''
        }
      />
    </>
  );
};

export default EventGuestsTable;
