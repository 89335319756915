/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ArrowLeft } from '@phosphor-icons/react';
import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import { ConditionalLoader } from 'components/Core/Loader';
import PostRsvpView from 'components/Events/Controls/Rsvp/PostRsvpView';
import PreRsvpView from 'components/Events/Controls/Rsvp/PreRsvpView';
import RsvpPauseStatus from 'components/Events/Controls/Rsvp/RsvpPauseStatus';
import AttendEvent from 'components/Events/Controls/Shared/AttendEvent';
import CopyEventLinkButton from 'components/Events/Controls/Shared/CopyEventLinkButton';
import PreviewCard from 'components/Events/Controls/Shared/PreviewCard';
import PreviewHeader from 'components/Events/Controls/Shared/PreviewHeader';
import PreEventSurveyModal from 'components/Events/Controls/Surveys/PreEventSurveyModal';
import { useAllRequiredSubmitted } from 'components/Events/Hooks/Generic';
import { ManagerContext } from 'components/Events/Manager/ManagerContext';
import { PublicEventContext } from 'components/Events/PublicEvent/PublicEventContext';
import DefaultLayout from 'components/shared/layout/DefaultLayout';
import { AuthContext } from 'contexts/AuthContext';
import { LayoutContext } from 'contexts/LayoutContext';
import useEventRelativeTime from 'hooks/useEventRelativeTime';
import { useGraphQLClient } from 'hooks/useGraphQLClient';
import { fullName } from 'utils/employee';
import { toPageName } from 'utils/text';
import { useContextWithFallback, ValidateEmail } from 'utils/utils';

const inviteeQueryDocument = gql`
  query inviteeQueryDocument($eventId: ID!, $email: String) {
    invitee(eventId: $eventId, email: $email) {
      id
      employeeId
      fullName
      status
    }
  }
`;

const EventView = ({ isPreview, isPublic: isPublicView, showRsvpButtons }) => {
  const {
    userProvider: { user },
  } = useContext(AuthContext);
  const { headerRef } = useContext(LayoutContext);
  const {
    actions: {
      handleRSVP,
      handleRsvpClick,
      setRsvpStatus,
      setShowPreEventSurvey,
    },
    state: {
      canEdit,
      event,
      inManagerContext,
      previousPath,
      rsvpStatus,
      showPreEventSurvey,
      surveyJustSubmitted,
      surveyResponses,
      surveys,
    },
  } = useContextWithFallback(ManagerContext, PublicEventContext);
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const [isEmailReady, setIsEmailReady] = useState(false);
  const [isSubmittingSurvey, setIsSubmittingSurvey] = useState(false);

  const graphQLClient = useGraphQLClient({ isPublic: true });

  const { isNow, isPast: isDone, isSoon } = useEventRelativeTime(event);

  useEffect(() => {
    if (isSubmittingSurvey && surveyJustSubmitted) {
      setIsSubmittingSurvey(false);
    }
  }, [isSubmittingSurvey, surveyJustSubmitted]);

  const publicAttendee = useMemo(
    () => ({
      attendeeEmail: surveyResponses?.pre?.email,
      attendeeName: fullName(surveyResponses?.pre),
    }),
    [surveyResponses]
  );

  const { data } = useQuery({
    enabled: Boolean(
      (user && user.id) ||
        (publicAttendee?.attendeeEmail &&
          publicAttendee?.attendeeEmail?.length > 0 &&
          ValidateEmail(publicAttendee?.attendeeEmail) &&
          isEmailReady)
    ),
    queryFn: async () =>
      await graphQLClient?.request(inviteeQueryDocument, {
        email: user?.email || publicAttendee?.attendeeEmail,
        eventId: Number(id),
        isPublic: true,
      }),
    queryKey: ['event', event?.id, 'invitee'],
  });

  const isPreEventSurveyResponded = useMemo(
    () =>
      surveys?.pre_event_survey?.survey_responses?.length > 0 &&
      surveys?.pre_event_survey?.survey_responses?.some(
        (response) =>
          response?.responder.email === user?.email ||
          response?.responder.email === publicAttendee?.attendeeEmail
      ),
    [
      publicAttendee?.attendeeEmail,
      surveys?.pre_event_survey?.survey_responses,
      user?.email,
    ]
  );

  useEffect(() => {
    if (!rsvpStatus && data?.invitee?.status) {
      setRsvpStatus(data?.invitee?.status);
    }
  }, [data?.invitee?.status, rsvpStatus, setRsvpStatus]);

  const allAnswersSubmitted = useAllRequiredSubmitted(
    surveys?.default_survey?.responses || [],
    surveys?.default_survey?.survey_questions
  );

  const handleSubmitPreEventSurvey = () => {
    setIsSubmittingSurvey(true);
    setShowPreEventSurvey(false);
    handleRSVP(rsvpStatus, true, false, true);
  };

  const isPreviewingDraft = useMemo(
    () => Boolean(!event.published && isPreview),
    [event, isPreview]
  );

  const rsvpIsYesOrMaybe = useMemo(
    () =>
      isPreviewingDraft
        ? true
        : Boolean(
            rsvpStatus?.length > 0 &&
              (rsvpStatus === 'rsvp' ||
                (rsvpStatus === 'maybe_attending' && !event.rsvp_pause_active))
          ),
    [event.rsvp_pause_active, isPreviewingDraft, rsvpStatus]
  );

  const inviteeIsWebinarHost = useMemo(
    () =>
      data?.invitee &&
      data?.invitee?.employeeId === event?.meeting?.creator_employee_id,
    [data?.invitee, event]
  );

  const isShowingCompleteSurveyButton = useMemo(
    () =>
      !!event?.pre_event_survey &&
      !isPreEventSurveyResponded &&
      rsvpStatus?.length > 0 &&
      rsvpStatus === 'rsvp' &&
      !showPreEventSurvey &&
      !isSubmittingSurvey,
    [
      isPreEventSurveyResponded,
      isSubmittingSurvey,
      rsvpStatus,
      showPreEventSurvey,
      event?.pre_event_survey,
    ]
  );

  return (
    <DefaultLayout
      button={
        <Stack direction='row' gap={1}>
          {canEdit && !isPublicView && !isPreview ? (
            <Button
              data-testid='back-to'
              onClick={() =>
                history.push(
                  previousPath || location?.state?.from || `/events/${event.id}`
                )
              }
              startIcon={<ArrowLeft />}
              variant='text'
            >
              Back to{' '}
              {location?.state?.from
                ? toPageName(location?.state?.from)
                : 'Event Manager'}
            </Button>
          ) : null}
          <CopyEventLinkButton />
        </Stack>
      }
      contentPaddingBottom={0}
      contentPaddingTop={0}
      title={<PreviewHeader />}
      withoutContainer={!!isPreview}
    >
      <ConditionalLoader conditions={[!event]}>
        <Stack
          alignItems='stretch'
          data-testid={`event-view-${inManagerContext ? 'manager' : 'public'}`}
          direction='column'
        >
          <Grid2
            alignItems='flex-start'
            container
            direction='row'
            spacing={3}
            sx={
              isPreview
                ? {}
                : {
                    maxHeight: `calc(100vh - ${
                      headerRef?.current?.clientHeight - 12
                    }px)`,
                    overflowY: 'auto',
                  }
            }
          >
            <Grid2 md={isPreview && !showRsvpButtons ? 12 : 7} xs={12}>
              <PreviewCard
                displayDifferentWebinarLink={inviteeIsWebinarHost}
                isDone={isDone}
                isEventPreview={isPreview}
                isNow={isNow}
                isSoon={isSoon}
                rsvpIsYesOrMaybe={rsvpIsYesOrMaybe}
              />
            </Grid2>
            {isPreview && !showRsvpButtons ? null : (
              <Grid2
                alignSelf='start'
                md={5}
                position='sticky'
                top='1px'
                xs={12}
              >
                <Stack direction='column' gap={3}>
                  {event?.rsvp_pause_active && !isDone && (
                    <RsvpPauseStatus rsvp={rsvpStatus} />
                  )}
                  {isPreview ? (
                    <PreRsvpView
                      allAnswersSubmitted={false}
                      event={event}
                      handleSelection={handleRsvpClick}
                      isDone={false}
                      isNow={false}
                      isPreview
                      isPublicView
                      isSoon={false}
                      rsvpIsYesOrMaybe={false}
                      setLeftEmailField={setIsEmailReady}
                      status={rsvpStatus}
                      user={null}
                    />
                  ) : ((isSoon || isNow) &&
                      rsvpStatus?.length > 0 &&
                      rsvpStatus !== 'guest_list' &&
                      rsvpStatus !== 'invited') ||
                    isDone ? (
                    <AttendEvent
                      allAnswersSubmitted={allAnswersSubmitted}
                      isDone={isDone}
                      isNow={isNow}
                      isSoon={isSoon}
                      rsvpIsYesOrMaybe={rsvpIsYesOrMaybe}
                      rsvpStatus={rsvpStatus}
                      user={user}
                    />
                  ) : (
                    <>
                      {['rsvp', 'maybe_attending', 'not_attending'].includes(
                        rsvpStatus
                      ) && allAnswersSubmitted ? (
                        <PostRsvpView
                          attendeeEmail={
                            data?.invitee?.email ||
                            publicAttendee?.attendeeEmail
                          }
                          attendeeName={
                            fullName(data?.invitee) ||
                            publicAttendee?.attendeeName
                          }
                          event={event}
                          handleSelection={handleRsvpClick}
                          isSoon={isSoon}
                          rsvpIsYesOrMaybe={rsvpIsYesOrMaybe}
                          status={rsvpStatus}
                        />
                      ) : !isDone ? (
                        <PreRsvpView
                          allAnswersSubmitted={allAnswersSubmitted}
                          cantRsvp={!isDone && event?.rsvp_pause_active}
                          event={event}
                          handleSelection={handleRsvpClick}
                          isDone={isDone}
                          isNow={isNow}
                          isPublicView={isPublicView}
                          isSoon={isSoon}
                          rsvpIsYesOrMaybe={rsvpIsYesOrMaybe}
                          setLeftEmailField={setIsEmailReady}
                          status={rsvpStatus}
                          user={user}
                        />
                      ) : null}
                      {isShowingCompleteSurveyButton ? (
                        <>
                          <Divider sx={{ mt: 1 }} />
                          <Box>
                            <Typography paragraph variant='body1'>
                              Help ensure a successful event by filling out the
                              survey below!
                            </Typography>
                            <Button
                              color='primary'
                              fullWidth
                              onClick={() => setShowPreEventSurvey(true)}
                              variant='contained'
                            >
                              Complete the survey
                            </Button>
                          </Box>
                        </>
                      ) : null}
                    </>
                  )}
                </Stack>
              </Grid2>
            )}
          </Grid2>
          {isPreview ? null : (
            <PreEventSurveyModal
              handleClose={() => setShowPreEventSurvey(false)}
              handleSubmit={handleSubmitPreEventSurvey}
              show={showPreEventSurvey}
              surveyDescription={event?.pre_event_survey?.description}
            />
          )}
        </Stack>
      </ConditionalLoader>
    </DefaultLayout>
  );
};

EventView.propTypes = {
  isPreview: PropTypes.bool,
  isPublic: PropTypes.bool,
  showRsvpButtons: PropTypes.bool,
};

export default EventView;
